export const ENV_CONF = 'ENV_CONF'
export const SIGNIN = 'SIGNIN'
export const AUTHENTICATED = 'AUTHENTICATED'
export const SIGNOUT = 'SIGNOUT'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const AUTH_ID = 'AUTH_ID'
export const REGISTER_EMAIL = 'REGISTER_EMAIL'
export const REGISTER_ID = 'REGISTER_ID'
export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT '
export const REFRESH_NOTIFICATIONS_FUNC = 'REFRESH_NOTIFICATIONS_FUNC'
export const ALERTS_COUNT = 'ALERTS_COUNT'
export const REFRESH_ALERTS_FUNC = 'REFRESH_ALERTS_FUNC'
export const GROUP_ID = 'GROUP_ID'
export const GROUP_ID_ADVANCED_POLICY = 'GROUP_ID_ADVANCED_POLICY'
export const DEFAULT_POLICY_RULE_ID = 'DEFAULT_POLICY_RULE_ID'
export const GROUP_ID_DASHBOARD = 'GROUP_ID_DASHBOARD'
export const OLD_GROUP_ID = 'OLD_GROUP_ID'
export const POLICY_TAB = 'POLICY_TAB'
export const HELP = 'HELP'
export const REFRESH_COMPANY_FEATURES = 'REFRESH_COMPANY_FEATURES'
export const AlertsVisibleColumns = 'AlertsVisibleColumns'
export const ALERT_ADVANCED_FILTER = 'ALERT_ADVANCED_FILTER'
export const IS_ALERT_ADVANCED_FILTER_PERMANENT = 'IS_ALERT_ADVANCED_FILTER_PERMANENT'
